/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, TitleMain, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text, Image, Subtitle, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu js-anim  --anim3 pb--06 pt--20" anim={"3"} menu={true} animS={null} border={null}>
            
            <Menu className="--full pb--06 pt--06" style={{"maxWidth":1540}} fullscreen={true}>
              
              <ColumnWrapper className="menu-logo-box" style={{"maxWidth":""}}>
                
                <TitleMain className="title-box fs--20 w--600 title-box--invert swpf--uppercase ls--01 lh--14 pl--0 pr--0" style={{"maxWidth":""}} content={"TVORBA WEBOVÝCH STRÁNEK"}>
                </TitleMain>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"#faq"} target={""} content={"Otázky a odpovědi"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"#cenik"} target={""} content={"Ceník"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr2 btn-box--sbtn2 fs--18 w--500 pl--08 pr--08" href={"/kontakt"} target={""} content={"Kontakt"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--sbtn1 btn-box--pbtn3 btn-box--shape3 fs--18 w--500 swpf--uppercase lh--1 pl--08 pr--08" innerClassName="pt--10 pb--10" href={"#objednavka-1"} target={""} content={"poptat služby"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-taxtt5 bg--bottom --full" anim={null} name={"uvod"} animS={null} border={null} parallax={false} fullscreen={true} backgroundPosition={null} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/5eec1e0b3f084e52abf296ce66c22694.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--2 pb--50 pt--50 flex--center" anim={null} animS={null} style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper className="mt--0 pl--0 pr--0" anim={null} animS={null} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":610}} content={"Dostaneme Vás On-line<br>"}>
              </Title>

              <Text className="text-box fs--22 w--500 text-box--invert mt--20" content={"<span style=\"font-weight: bold; color: rgb(26, 115, 232);\">✓</span><span style=\"color: rgb(213, 164, 106);\">&nbsp;</span> Vytvoříme webové stránky dle vašich požadavků"}>
              </Text>

              <Text className="text-box fs--22 w--500 text-box--invert mt--10" content={"<span style=\"font-weight: bold; color: rgb(26, 115, 232);\">✓</span><span style=\"color: rgb(213, 164, 106);\">&nbsp;</span> Stránky můžete sami jednoduše upravovat"}>
              </Text>

              <Text className="text-box fs--22 w--500 text-box--invert mt--10" content={"<span style=\"font-weight: bold; color: rgb(26, 115, 232);\">✓</span><span style=\"color: rgb(213, 164, 106);\">&nbsp;</span> Realizace do 7 dní"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 swpf--uppercase mt--30" href={"#cenik"} content={"Ceník – <span style=\"font-weight: bold;\">9 900 Kč</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s3 --right pb--0 pl--0 pr--0" anim={"4"} animS={"3"} style={{"maxWidth":""}} border={null}>
              
              <Image className="mb--20 mt--0" alt={""} src={"https://cdn.swbpg.com/o/12956/2bf62cbeab7c45eebfb9a4911beaedc8.svg"} svg={false} ratio={null} sizes={"100vw"} style={{"maxWidth":580}} srcSet={""} content={null} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"sluzby-1"} style={{"backgroundColor":"rgba(25, 22, 34, 1)"}}>
        </Column>


        <Column className="css-c7rm0u mb--40 mt--80 pb--80 pt--50" name={"1p1yygx8jri"} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/9657a764f35247579bb607bbc825e882.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--3 pb--20 pl--40 pr--40" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --left pb--25 pl--25 pr--25 pt--25" anim={"2"} animS={"3"} style={{"maxWidth":480}} border={null}>
              
              <Title className="title-box fs--48 w--600 ls--004 mt--06" style={{"maxWidth":294}} content={"OFICIÁLNÍv<br>"}>
              </Title>

              <Title className="title-box fs--36 w--600 ls--004 mt--02" style={{"maxWidth":294}} content={"<span style=\"color: rgb(26, 115, 232);\">ČESKÝ</span> PARTNER !<br>"}>
              </Title>

              <Image className="--left mt--12" alt={""} src={"https://cdn.swbpg.com/o/12956/aa4c2d9ee4894ceabbf6fbe382714f76.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":260}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape4 --border4 js-anim  --anim2 --anim-s3 --left pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":480,"backgroundColor":"rgba(255,255,255,1)"}} border={"4"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"<span style=\"color: rgb(26, 115, 232);\">Responzivní</span><br>zobrazení stránek<br>"}>
              </Title>

              <Text className="text-box w--500 ls--002 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(121, 121, 121);\">Optimalizované zobrazení webových stránek v mobilu, tabletu a desktopů.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape4 --border4 js-anim  --anim2 --anim-s3 --left pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":480,"backgroundColor":"rgba(255,255,255,1)"}} border={"4"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"<span style=\"color: rgb(26, 115, 232);\">Jednoduše</span><span style=\"color: rgb(213, 164, 106);\">&nbsp;<br></span><span style=\"color: rgb(44, 39, 63);\">editujte stránky</span>"}>
              </Title>

              <Text className="text-box w--500 ls--002 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(121, 121, 121);\">Vytvořené webové stránky si následně můžete sami a snadno editovat.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape4 --border4 js-anim  --anim2 --anim-s3 --left mt--12 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":480,"backgroundColor":"rgba(255,255,255,1)"}} border={"4"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"<span style=\"color: rgb(26, 115, 232);\">Komplexní</span><br>řešení stránek<br>"}>
              </Title>

              <Text className="text-box w--500 ls--002 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(109, 112, 134);\">Webové stránky můžete propojit s GoogleAnalytics, Adwords, FB pixel a dalšími nástroji.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape4 --border3 js-anim  --anim2 --anim-s3 --left mt--12 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":480,"backgroundColor":"rgba(255,255,255,1)"}} border={"3"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"<span style=\"color: rgb(26, 115, 232);\">Proklatě rychlé </span><span style=\"color: rgb(44, 39, 63);\">načítání stránek</span><br>"}>
              </Title>

              <Text className="text-box w--500 ls--002 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(121, 121, 121);\">Rychlé webové stránky jsou uživatelky přívětivější, což do jisté míry oceňují uživatelé i vyhledávače.\n</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape4 --border3 js-anim  --anim2 --anim-s3 --left mt--12 pb--40 pl--40 pr--40 pt--40" anim={"2"} animS={"3"} style={{"maxWidth":480,"backgroundColor":"rgba(255,255,255,1)"}} border={"3"}>
              
              <Title className="title-box fs--30 w--600 ls--004" style={{"maxWidth":316}} content={"<span style=\"color: rgb(26, 115, 232);\">SSL</span><br>zabezpečení stránek<br>"}>
              </Title>

              <Text className="text-box w--500 ls--002 mt--16" style={{"maxWidth":328}} content={"<span style=\"color: rgb(121, 121, 121);\">Zajištění bezpečné HTTPS komunikace. K zajištění HTTPS je použit certifikát&nbsp;Let's Encrypt.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center mb--10 pb--80 pt--20" name={"rhicxwc6zkh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(121, 121, 121);\">Zavolejte nám:</span>"}>
              </Text>

              <Title className="title-box title-box--center mt--02" content={"<span style=\"color: rgb(216, 216, 255);\">+ 420</span> 724 949 630"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--20 pt--50" name={"jak"} style={{"backgroundColor":"rgba(44,39,63,1)"}} parallax={true}>
          
          <ColumnWrap className="column__flex --center el--1 mt--08" columns={"1"}>
            
            <ColumnWrapper className="pb--40 pt--10">
              
              <Title className="title-box fs--48 w--600 title-box--invert lh--12" style={{"maxWidth":910}} content={"Jak to funguje?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3 mb--20 flex--center" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="--hvr3 --shape3 --border2 js-anim  --anim5 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"5"} animS={"3"} style={{"backgroundColor":"rgba(24, 22, 33, 0.09)"}} border={"2"}>
              
              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--48 w--600 ls--004 lh--1" innerClassName="pt--16 pb--12 pl--08 pr--08" href={"#objednavka-1"} content={"1"}>
              </Button>

              <Subtitle className="subtitle-box fs--24 subtitle-box--invert" style={{"maxWidth":386}} content={"Poptejte tvorbu webových stránek"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr3 --shape3 --border2 js-anim  --anim7 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"7"} animS={"3"} border={"2"}>
              
              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--48 w--600 ls--004 lh--1" innerClassName="pt--16 pb--12 pl--08 pr--08" href={"#objednavka-1"} content={"2"}>
              </Button>

              <Subtitle className="subtitle-box fs--24 subtitle-box--invert" style={{"maxWidth":""}} content={"Budeme Vás kontaktovat ohledně podkladů pro webové stránky"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr3 --shape3 --border2 js-anim  --anim4 --anim-s3 pb--40 pl--40 pr--40 pt--40" anim={"4"} animS={"3"} border={"2"}>
              
              <Button className="btn-box btn-box--pbtn2 btn-box--shape3 fs--48 w--600 ls--004 lh--1" innerClassName="pt--16 pb--12 pl--08 pr--08" href={"#objednavka-1"} content={"3"}>
              </Button>

              <Subtitle className="subtitle-box fs--24 subtitle-box--invert" style={{"maxWidth":386}} content={"Webové stránky vytvoříme a spustíme"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 mb--20 mt--50 flex--center" style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--20 pl--20 pr--20 pt--20" anim={"2"} animS={"3"} style={{"maxWidth":600}} border={null}>
              
              <Subtitle className="subtitle-box fs--18 subtitle-box--invert" style={{"maxWidth":386}} content={"⭐ ⭐ ⭐&nbsp;⭐&nbsp;⭐"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--18 subtitle-box--invert mt--08" style={{"maxWidth":386}} content={"<a href=\"#objednavka-1\">Poptat tvorbu webových stránek</a>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"xkw1uubmhd"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"cenik"}>
          
          <ColumnWrap className="column__flex el--1 pl--40 pr--40 flex--top" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Ceník webových stránek</span><br>"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":457}} content={"<span style=\"color: rgb(109, 112, 134);\">Naše ceny jsou transparentní. Vytvoříme vám webové stránky, které si můžete dále sami a jednoduše upravovat.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --center el--3 mt--60 pl--40 pr--40 pt--0 flex--top" anim={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper className="--hvr2 --shape3 --border3 --center pb--40 pl--40 pr--40 pt--40" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"3"}>
              
              <Title className="title-box fs--30 w--600 lh--12" style={{"maxWidth":351}} content={"<span style=\"color: rgb(44, 39, 63);\">Jednostránkový web</span>"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">1. Profesionální design</span>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":464}} content={"<span style=\"color: rgb(121, 121, 121);\">&nbsp;Vytvoření online vizitky, nebo základní stránky.&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 lh--14 mt--20" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">2. Obsah služby</span>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> pouze hlavní stránka<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> poptávkový formulář<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> možnost vlastní editace<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;registrace / převod domény<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> SSL zabezpečení<br>"}>
              </Text>

              <Title className="title-box fs--43 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">9 900 Kč&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box fs--16 w--500 lh--12 mt--02" content={"<span style=\"color: rgb(44, 39, 63);\">bez DPH</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 fs--18 swpf--uppercase mt--20" href={"#objednavka-1"} content={"ObjednaT službu"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape3 --border2 --center pb--40 pl--40 pr--40 pt--40" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"2"}>
              
              <Title className="title-box fs--30 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Vícestránkový web</span>"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">1. Profesionální design</span><br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":237}} content={"<span style=\"color: rgb(121, 121, 121);\">Vytvoření moderní firemní webové stránky.&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 lh--14 mt--20" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">2. Obsah služby</span><br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> až 10 podstránek<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> použití animací<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> poptávkový formulář<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> možnost vlastní editace<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> registrace / převod domény<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> SSL zabezpečení<br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 lh--14 mt--20" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">3. Extra služby</span>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;konzultace a tel. podpora 1,5h<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;sleva 15% na služby Saywebpage<br>"}>
              </Text>

              <Title className="title-box fs--43 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">od 18 990 Kč&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box mt--02" style={{"maxWidth":436}} content={"bez DPH<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 fs--18 swpf--uppercase mt--20" href={"#objednavka-1"} content={"OBJEDNAT SLUŽBU"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--hvr2 --shape3 --border3 --center pb--40 pl--40 pr--40 pt--40" anim={null} style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={"3"}>
              
              <Title className="title-box fs--30 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Deluxe web</span>"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">1. Profesionální design</span><br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":237}} content={"<span style=\"color: rgb(121, 121, 121);\">To nejlepší co umíme pro firemní webové stránky.&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 lh--14 mt--20" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">2. Obsah služby</span><br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> až 25 podstránek<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> použití animací<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> poptávkový formulář<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> možnost vlastní editace<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> registrace / převod domény<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":469}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span> SSL zabezpečení<br>"}>
              </Text>

              <Text className="text-box fs--20 w--500 lh--14 mt--20" style={{"maxWidth":468}} content={"<span style=\"color: rgb(26, 115, 232);\">3. Extra služby</span>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;konzultace a tel. podpora 3h<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;sleva 25% na služby Saywebpage<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;návrh nového loga<br>"}>
              </Text>

              <Text className="text-box mt--08" style={{"maxWidth":436}} content={"<span style=\"color: rgb(74, 144, 226);\">✓</span>&nbsp;návrh šablony pro vizitky<br>"}>
              </Text>

              <Title className="title-box fs--43 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">od 34 990 Kč&nbsp;</span><br>"}>
              </Title>

              <Text className="text-box mt--02" style={{"maxWidth":436}} content={"bez DPH<br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3 fs--18 swpf--uppercase mt--20" href={"#objednavka-1"} content={"OBJEDNAT SLUŽBU"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":1140}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":"","backgroundColor":"rgba(255,255,255,1)"}} border={null}>
              
              <Text className="text-box fs--16 w--500 lh--12 mt--02" content={"<span style=\"color: rgb(44, 39, 63);\">Webové stránky jsou vytvořeny na platformě <a href=\"https://saywebpage.com/cs/\">Saywebpage.com</a>&nbsp;</span><br>"}>
              </Text>

              <Text className="text-box fs--16 w--500 lh--12 mt--02" content={"<span style=\"color: rgb(44, 39, 63);\"><a href=\"https://saywebpage.com/cs/cenik-sluzby/\">Zjistěte více o ceníku služby</a> Saywebpage.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"22qxlnb21sx"} style={{"backgroundColor":"rgba(243, 246, 255, 1)"}} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Reference"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":404}} content={"Realizovali jsme již více než 50 webových stránek. Podívejte se na některé z nich."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--2 mt--30" style={{"maxWidth":1100}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=860x_.jpg 860w, https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/12956/25eae9d631814285875744c817088753_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"proficomfort.cz"}>
              </Subtitle>

              <Text className="text-box mt--06" style={{"maxWidth":467}} content={"Návrhy a dodávky <a href=\"https://proficomfort.cz/\">infratopení</a> a topných systémů."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=860x_.jpg 860w, https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/12956/d81f296fb2924c5e907418c66ef790be_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"minibagry.cz"}>
              </Subtitle>

              <Text className="text-box mt--06" style={{"maxWidth":467}} content={"<a href=\"https://minibagry-plzensko.cz/\">Půjčovna minibagrů</a> pro malé a střední výkopové práce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=860x_.jpg 860w, https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/12956/f0df9c8059df43a2a75ce895b0bff5da_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"cisteni-dpf-ostrava.cz"}>
              </Subtitle>

              <Text className="text-box mt--06" style={{"maxWidth":467}} content={"Profesionálne <a href=\"https://cisteni-dpf-ostrava.cz/\">čištění DPF</a> filtru v Ostravě a okolí."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=860x_.jpg"} svg={false} ratio={"3:2"} sizes={"100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=350x_.jpg 350w, https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=660x_.jpg 660w, https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=860x_.jpg 860w, https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/12956/fcd914d509bf4d9bbf3313369ca65b00_s=2000x_.jpg 2000w"} content={null} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Subtitle className="subtitle-box mt--16" content={"mat-okna.cz"}>
              </Subtitle>

              <Text className="text-box mt--06" style={{"maxWidth":467}} content={"Prodej, montáž a servis designových oken a dvěří."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-amc2l4 --center bg--center pb--40 pt--80" name={"objednavka-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/3c6efb24f2514f4eb53a8cbd0027b05d.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pl--40 pr--40 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Objednávka<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-uieyox bg--center-h pb--60 pl--20 pr--20" name={"objednavka-2"} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/e9dbad86b62a45fcba43d37383e2c2a1.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --shape5 js-anim  --anim2 el--2 pb--20 pl--40 pr--40 pt--20 flex--center" anim={"2"} style={{"maxWidth":1100,"backgroundColor":"rgba(26, 115, 232, 0.08)"}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":538}}>
              
              <Image className="--left" alt={""} src={"https://cdn.swbpg.com/o/12956/6c00f8b8b24b45d8922892261ab0c379.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":352}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --shape3" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Vyberte službu","type":"select","options":["Jednostránkový web","Vícestránkový web","Deluxe web"],"required":true,"placeholder":"Vyberte službu"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Nezávazně objednat","type":"submit"},{"name":"Zaškrtávátko","type":"checkbox"}]} layout={"l2"}>
              </ContactForm>

              <Text className="text-box fs--13 mt--12" content={"Kliknutím na odeslat souhlasíte se zpracování osobních dat."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--80" name={"faq"}>
          
          <ColumnWrap className="column__flex --center el--1 pl--40 pr--40 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--48 w--600 lh--12" content={"<span style=\"color: rgb(44, 39, 63);\">Otázky &amp; odpovědi</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b71ko6 pb--80 pl--16 pr--16 pt--16" name={"uwhjufg46ks"} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/79a901de1d7e44ef80d27e864a94ac78.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --shape3 --border3 js-anim  --anim2 --anim-s4 --left el--2 pb--30 pl--40 pr--40 pt--30 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":605}}>
              
              <Title className="title-box fs--30 w--600 lh--12 mt--0" style={{"maxWidth":406}} content={"<span style=\"color: rgb(44, 39, 63);\">Připravujeme..</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":605}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(109, 112, 134);\">Aktuálně revidujeme vaše časté dotazy, na které dodáváme odpovědi.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --border2 js-anim  --anim2 --anim-s4 --left el--1 mb--20 mt--20 pb--20 pl--40 pr--40 pt--20 flex--top" anim={"2"} animS={"4"} style={{"maxWidth":1200}} border={"2"} columns={"1"}>
            
            <ColumnWrapper className="--left mt--0" style={{"maxWidth":""}}>
              
              <Button className="btn-box btn-box--sbtn2 btn-box--filling2 btn-box--center fs--20 w--600 ls--002" href={"#objednavka-1"} content={"Nezávazně objednat tvorbu webové stránky"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1ic7l9g bg--top pb--80 pt--80" name={"p0zcyis97o"} style={{"backgroundColor":"rgba(4, 4, 41, 1)"}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/o/12956/61168d9d2d78499e8919b17655c604bc.svg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--60 pt--40" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 w--900 title-box--invert" style={{"maxWidth":1190}} content={"Věříme, že kvalitní webové stránky nemusí stát majlant"}>
              </Title>

              <Button className="btn-box btn-box--hvr3 btn-box--shape3" href={"#objednavka-1"} content={"Chci vytvořit web"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--16" style={{"backgroundColor":"rgba(255,255,255,1)"}} border={null} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/12956/2d9d056e7c4e4d8ba96f58c3af94d895.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":214}} srcSet={""} content={null}>
              </Image>

              <Title className="title-box fs--20" content={"Tvorba-webových-stránek.cz"}>
              </Title>

              <Text className="text-box mt--06" style={{"maxWidth":334}} content={"poptat@tvorba-webovych-stranek.cz<br>+420 724 949 630"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box" content={"10 let zkušeností\n"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":269}} content={"Vytváříme moderní webové stránky s minimalistickým a přehledným designem již více jak 10 let."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--right" content={"Navigace"}>
              </Title>

              <Text className="text-box text-box--right" content={"<a href=\"#uvod\">Zpět nahoru<br></a><a href=\"#jak\">Jak to funguje</a><a href=\"#1p1yygx8jri\"><br>Ceník služby</a><a href=\"#uvod\"><br></a><a href=\"#objednavka-1\">Objednávka<br></a><a href=\"/kontakt\">Kontakt</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}